import React from 'react';
import './App.css';
import logo from './logo.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to Sign Interpreter</h1>
        <p>
          We translate text into sign language using AI
        </p>
        <video width="600" controls>
          <source src="/Signified Demonew.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <p>
          Join us in making communication accessible for everyone!
        </p>
        <p>
          Email us at mark@signinterpreter.shop to find out more
        </p>
      </header>

    </div>
  );
}

export default App;
